// 传入的是开始报名的时间
export const resideTime =(applyTime,endtime)=>{
    // 当前时间时间戳
    const startTime = +new Date()
    // 将报名开始时间转换为时间戳
    const isapplyTime = +new Date(applyTime)
    // 将报名结束时间转换为时间戳
    const isendtime  = + new Date(endtime)
    // 当前时间和报名开始时间差
    const dec =  isapplyTime - startTime 
    const decs = isendtime - startTime
    if(dec > 0 && decs > 0){
        let h = Math.floor(dec/1000/60/60)
        let m = Math.floor(dec/1000/60%60)
        let s = Math.floor(dec/1000%60)
    return `${h>=10?h:'0'+ h}:${m >=10?m:'0'+m}:${s>=10?s:'0'+s}后报名开始`
    }else if(dec < 0 && decs > 0){
        let h = Math.floor(decs/1000/60/60)
        let m = Math.floor(decs/1000/60%60)
        let s = Math.floor(decs/1000%60)
        return `${h>=10?h:'0'+ h}:${m >=10?m:'0'+m}:${s>=10?s:'0'+s}后截止报名`
    }else if(dec < 0 && decs < 0){
        return '报名已结束'
    } 
}
// 时间转换为年月日
export const result = (val)=>{
    let a = val.split(' ')
    const current  = new Date(a[0])
    const year = current.getFullYear()
    const month = current.getMonth()+1
    const date = current.getDate()
    return `${month}月${date}日 ${a[1]}`
}