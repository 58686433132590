<template>
  <div class="kecheng">
    <img class="tui-img" v-if="imgList.img" :src="imgList.img" alt="" />

    <div class="kmain">
      <div class="isTitle" v-if="recommendList.length">
        推荐<span class="redColor">营期</span>
      </div>
      <div class="recommendList">
        <div class="listItem" @click.stop="imgxq(index, item)" v-for="(item, index) in recommendList" :key="index">
          <div class="showImg">
            <img :src="item.campCover" />
          </div>
          <div class="content">
            <div class="campName">{{ item.campName }}</div>
            <div class="campIntroduce">
              <div class="chapter">
                共{{ item.linkNum }}节/{{ item.taskNum }}个任务
              </div>
              <div class="signUp">{{ item.virtualApply }}人已报名</div>
            </div>
            <div class="campPrice">
              <div class="isPrice">￥{{ item.tenantPrice }}</div>
              <div class="isSignUp" @click.stop="isSignUo(item)">立即报名</div>
            </div>
          </div>
        </div>
        <!-- 左右滑动按钮 -->
        <div v-if="recommendList.length" class="left" @click="goLeft"></div>
        <div v-if="recommendList.length" class="right" @click="goRight"></div>
      </div>
      <div class="recommendCamp"></div>
      <div class="isTitle allCamp">全部<span class="redColor">营期</span></div>
      <!-- 筛选部分 -->
      <div class="isFilter">
        <div class="filterModel">
          <div class="text">选择学院：</div>

          <div class="choseSchool">
            <div class="schoolItem" @click="choseAll" :class="[!currentSchool ? 'ison' : '']">
              全部
            </div>
            <div class="schoolItem" v-for="(item, index) in schoollist" :key="index" @click="isSchool(item)"
              :class="currentSchool == item.pKind ? 'ison' : ''">
              {{ item.pName }}
            </div>
          </div>
        </div>
        <div class="filterModel" v-if="kindList.length !== 0">
          <div class="text">选择项目：</div>
          <div class="choseSchool">
            <div class="schoolItem" @click="choseAll1" :class="[!currentKind ? 'ison' : '']">
              全部
            </div>
            <div class="schoolItem" v-for="(item, index) in kindList" :key="index" @click="isKind(item)"
              :class="currentKind == item.pKind ? 'ison' : ''">
              {{ item.pName }}
            </div>
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="kecent">
        <!-- <router-view /> -->
        <div class="kecents" v-for="(item, index) in list" :key="index" @click="imgxq(index, item)">
          <div class="imgBox">
            <img :src="item.campCover" />
            <!-- 报名未开始标签 -->
            <div class="tag nostart" v-if="resideTime(item.applyStart, item.applyEnd).indexOf(
              '报名开始'
            ) !== -1
              ">
              {{ resideTime(item.applyStart, item.applyEnd) }}
            </div>
            <!-- 报名开始标签 -->
            <div class="tag start" v-if="resideTime(item.applyStart, item.applyEnd).indexOf(
              '截止报名'
            ) !== -1
              ">
              {{ resideTime(item.applyStart, item.applyEnd) }}
            </div>
            <!-- 报名结束标签 -->
            <div class="tag end" v-if="resideTime(item.applyStart, item.applyEnd).indexOf(
              '报名已结束'
            ) !== -1
              ">
              {{ resideTime(item.applyStart, item.applyEnd) }}
            </div>
          </div>
          <!-- <router-link :to="'/typeclassxq?id=' + item.id+'&tel='+form+'&uid='+uid+'&tenantid='+search.tenantid+'&type='+search.type+'&entrantsPrice='+item.entrantsPrice+'&jgPrice='+item.jgPrice"></router-link> -->
          <div class="kecentsCen">
            <div class="kecentsCenTop twoEllipsis">
              <div class="k_til">{{ item.campName }}</div>
            </div>
            <div class="isDetail">
              <div class="allChapter">
                共{{ item.linkNum }}节/{{ item.taskNum }}个任务
              </div>
              <div class="signUp">{{ item.virtualApply }}人已报名</div>
            </div>
            <div class="kecentsCenBtm">
              <div class="w-b-100">
                <span class="price"><span class="tips">¥ </span>{{ item.tenantPrice }}</span>
                <!-- <span class="oldPrice"><span class="tips">¥ </span>{{ item.olderPrice }}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div class="nothing" v-if="!list || list.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        margin: 40px auto;
        margin-bottom: 80px;
      ">
      <el-pagination class="pagination" :total="total" :current-page="pageNum" :page-sizes="[12, 24, 28, 32]"
        :page-size="pageSize" :pager-count="5" @current-change="handleCurrentChange" @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { resideTime } from "./residueTime";
import { getCampList, getProductRecommendCamp, addOrders } from "@/api/camp";
import KindChooseView from "../../components/kindChooseViewktypevip.vue";
import {
  selectKindAllCollage,
  getSurfaceList,
  getTenantKindNoZyList,
} from "@/api/home";
import { getDomain, getInfo } from "@/api/cookies";
export default {
  components: { KindChooseView },
  data() {
    return {
      imgList: {},
      list: {},
      search: {},
      pageSize: 12,
      pageNum: 1,
      total: 0,
      kind: null,
      zyId: null,
      schoole: null,
      domain: null,
      schoollist: [],
      kindList: [],
      currentSchool: 0,
      currentKind: null,
      userInfo: {},
      recommendList: [],
      resideTime: resideTime,
    };
  },
  async created() {
    this.userInfo = getInfo();
    // 获取学院列表
    getTenantKindNoZyList(4).then((res) => {
      if (res.code == 0) {
        this.schoollist = res.data;
      }
    });
    this.domain = getDomain();
    getSurfaceList(4, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        if (res.msg.length > 0) {
          this.imgList = res.msg[0];
        } else {
          this.imgList = {};
        }
      }
    });
    if (this.$route.query.kind) {
      this.kind = await Number(this.$route.query.kind);
    }
    if (this.$route.query.zId) {
      this.zyId = await Number(this.$route.query.zId);
    }
    if (this.$route.query.schoole) {
      this.schoole = await Number(this.$route.query.schoole);
    }
    this.getRecommendList();
    this.getList();
  },
  methods: {
    isSchool(val) {

      this.currentSchool = val.pKind;
      this.currentKind = null;
      this.kindList = val.kindList;
      this.getList();
    },
    isKind(val) {

      this.currentKind = val.pKind;
      this.getList();
    },
    // 获取推荐营期列表
    getRecommendList() {
      getProductRecommendCamp({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        tenantId: this.userInfo.tenantId,
      }).then((res) => {
        this.recommendList = res.rows;

      });
    },
    // 获取知识套餐列表
    getList() {
      getCampList({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        tenantId: this.userInfo.tenantId,
        bigKind: this.currentSchool ? this.currentSchool : undefined,
        kindId: this.currentKind ? this.currentKind : undefined,
      }).then((res) => {
        this.list = res.rows;
        this.total = res.total;
      });
    },
    choseAll() {
      this.currentSchool = null;
      this.currentKind = null;
      this.kindList = [];
      this.getList();
    },
    choseAll1() {
      this.currentKind = null;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    // 筛选
    isOk(val) {
      this.search = val;
      this.pageNum = 1;
      this.getList();
    },
    isSignUo(item) {
      // 报名接口
      addOrders({
        commodityType: [20],
        id: [item.id],
        commodityName: [item.campName],
        orderName: item.campName,
        orderSource: "PC端",
        userid: this.userInfo.id,
      }).then((res) => {
        if (res.code == 0) {
          this.$router.push({
            name: "个人中心订单页",
            params: { orderNumber: res.msg },
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 点击图片跳转到详情页
    imgxq(index, item) {
      /* this.$router.push({
        name: "知识套餐课详情",
        query: {
          id: this.list[index].id,
          tenantid: this.search.tenantid,
          type: this.search.type,
          entrantsPrice: this.list[index].entrantsPrice,
          jgPrice: this.list[index].jgPrice,
        },
      }); */
      let routeUrl = this.$router.resolve({
        name: "训练营详情",
        query: {
          id: item.id,
          tenantid: this.userInfo.tenantId,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    goRight() {
      const scrollBox = document.querySelector(".recommendList");
      scrollBox.scrollLeft += 200;
      scrollBox.scrollTo(scrollBox.scrollLeft, 0);
    },
    goLeft() {
      const scrollBox = document.querySelector(".recommendList");
      scrollBox.scrollLeft -= 200;
      scrollBox.scrollTo(scrollBox.scrollLeft, 0);
    },
  },
};
</script>
<style lang="less" scoped>
.tui-img {
  margin: 0;
  width: 100%;
}

/deep/ .kmain {
  position: relative;
  width: 1220px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin: 24px auto;

  .recommendList {
    white-space: nowrap;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &>.listItem:last-child {
      margin-right: 0 !important;
    }

    .left {
      position: absolute;
      top: 183px;
      left: -80px;
      width: 47px;
      height: 47px;
      background: url("~@/style/image/camp/goLeft.png") no-repeat top left / 100% 100%;
    }

    .right {
      position: absolute;
      top: 183px;
      right: -80px;
      width: 47px;
      height: 47px;
      background: url("~@/style/image/camp/goRight.png") no-repeat top left / 100% 100%;
    }

    .listItem {
      display: inline-block;
      width: 224px;
      height: 315px;
      margin-right: 25px;

      .content {
        background-color: #fff;
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .campName {
          //   font-size: 16px;
          //   font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          //   font-weight: 400;
          //   color: #333333;
          //   text-overflow: -o-ellipsis-lastline;
          //   overflow: hidden; //溢出内容隐藏
          //   text-overflow: ellipsis; //文本溢出部分用省略号表示
          //   display: -webkit-box; //特别显示模式
          //   -webkit-line-clamp: 2; //行数
          //   line-clamp: 2;
          //   -webkit-box-orient: vertical; //盒子中内容竖直排列
          white-space: pre-wrap;
          height: 48px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          background: none;
          text-align: left;
        }

        .campIntroduce {
          display: flex;
          justify-content: space-between;
          margin-bottom: 26px;

          .chapter {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          .signUp {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }

        .campPrice {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .isPrice {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ff5e51;
          }

          .isSignUp {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 35px;
            background: #ff5e51;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }

      .showImg {
        width: 100%;
        height: 149px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .isTitle {
    font-size: 24px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #ff5e51;
    margin-top: 56px;
    margin-bottom: 31px;

    .redColor {
      color: black;
    }
  }

  .allCamp {
    margin-top: 33px !important;
    margin-bottom: 23px !important;
  }

  .isFilter {
    padding: 30px 22px;
    background-color: #fff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
  }

  .filterModel {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .text {
      color: #999999;
    }

    .choseSchool {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .schoolItem {
        color: #666666;
        margin-right: 32px;
        cursor: pointer;
      }
    }
  }
}

/deep/ .box-div {
  border-radius: 8px 8px 8px 8px;
}

/deep/ .zychoice {
  // height: 82px;
  // line-height: 82px;
  border-radius: 8px 8px 8px 8px;
  padding-left: 32px;
}

.kecheng {
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;

  // 内容
  .kecent {
    width: calc(1220px + 24px);
    height: auto;
    margin: 30px 0px 0px calc(50% - 610px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #f5f7fa;

    .kecents {
      width: 287px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(237, 239, 242, 0.7);
      cursor: pointer;
      cursor: pointer;
      margin: 0px 24px 24px 0px;
      border-radius: 8px 8px 8px 8px;

      img {
        width: 100%;
        height: 161px;
        cursor: pointer;
      }

      .boutique {
        width: 100%;
        margin: auto;
        height: 158px;
        position: relative;
        padding: 12px 16px 16px;
        background-image: url("../../assets/img/Home/boutiqueBan.png");
        background-size: 100% 100%;

        .kecentsCenTop {
          color: #5a4629 !important;
        }

        .more {
          color: #5a4629 !important;
          font-size: 12px !important;
        }
      }

      .kecentsCen {
        width: 100%;
        margin: auto;
        height: 150px;
        position: relative;
        padding: 12px 16px 16px;

        .isDetail {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .allChapter {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          .signUp {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }

        .kecentsCenTop {
          /*  font-size: 18px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 26px; */
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          height: 48px;
          display: flex;

          div {
            width: 36px;
            height: 20px;
            background: linear-gradient(132deg, #ff9051 0%, #ff5e51 100%);
            border-radius: 3px 3px 3px 3px;
            opacity: 1;
            line-height: 20px;
            text-align: center;
            margin-right: 8px;
            margin-top: 2px;

            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }

          .k_cl {
            margin-top: 5px;
          }

          .k_til {
            width: 254px;
            height: 48px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            background: none;
            text-align: left;
          }
        }

        .kecentsCenCen {
          margin-top: 12px;
          // height: 20px;
          // background-color: #fff2e9;
          border-radius: 2px 2px 2px 2px;
          display: inline-block;
          opacity: 1;
          // padding: 0px 8px;
          // line-height: 20px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
        }

        .boutiqueTxt {
          margin-top: 12px;
          width: 255px;
          height: 26px;
          display: inline-block;
          padding: 0px 8px;
          line-height: 26px;
          font-size: 12px;
          background: none;
          background-image: url("../../assets/img/Home/boutiqueTxt.png") !important;
          background-size: 100% 100%;
          color: #a57800;
          // padding-left: 83px;
        }

        .kecentsCenBtm {
          position: absolute;
          bottom: 18px;
          left: 16px;
          right: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .boutiquePrice {
            color: #5a4629 !important;
          }

          .price {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular;
            font-weight: bold;
            color: #ff5e51;
            line-height: 18px;

            .tips {
              font-size: 14px;
              font-weight: 400;
              line-height: 14px;
            }
          }

          .oldPrice {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
            text-decoration: line-through;
            margin-left: 8px;
          }

          .more {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
            margin-left: 8px;
            flex-shrink: 0;
            align-items: center;

            .img {
              width: 13px;
              height: 13px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.imgBox {
  position: relative;

  .tag {
    padding: 0px 16px;
    position: absolute;
    top: 10px;
    left: -16px;
    color: #fff;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }

  .nostart {
    line-height: 34px;
    text-align: center;
    background: url("~@/assets/img/camp/applyBg.png") no-repeat right top / 100% 100%;
    transform: scale(0.9);
  }

  .start {
    line-height: 34px;
    text-align: center;
    background: url("~@/assets/img/camp/campstart.png") no-repeat right top / 100% 100%;
    transform: scale(0.9);
  }

  .end {
    background: url("~@/assets/img/camp/finish.png") no-repeat right top / 100% 100%;
    border-radius: 4px 17px 17px 4px;
    opacity: 1;
    transform: scale(0.9);
    line-height: 34px;
    text-align: center;
    padding: 0px 21px 0px 16px !important;
  }
}

.isNull {
  width: 1200px;
  margin: 0px auto;
}

// .curForms {
//   background: #ffffff;
// }
/deep/ .el-pagination .active {
  background: #ff5e51 !important;
}

/deep/ .on {
  background: #ecf2ff !important;
  color: #1061ff !important;
}

.ison {
  padding: 4px 6px;
  background: #f5f6f6;
  border-radius: 4px 4px 4px 4px;
  color: #ff5d51 !important;
}
</style>
